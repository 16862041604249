import * as React from 'react';
import eblLogoLg from '../../images/ebl-logo-transparent.png'
import useRedirectToHttps from '../../middleware/useRedirectToHTttps'
export default function Home () {
useRedirectToHttps()
  return (
    <>
        <div id="overlay"></div>
        <div className="index">
        <div style={{ textAlign: 'center' }}>
            <img className="tempLogo" src={eblLogoLg} alt="EBL Construction logo" />
            <h1>Full website coming soon.</h1>
        </div>
        </div>
    </>
  );
}
