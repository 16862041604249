import React from 'react';
import './App.css';
import { Router, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Home from './components/pages/Home'
function App() {
  const history = createBrowserHistory()
  return (
    <div className="App">
      <Router location={window.location} navigator={history}>
        <Routes>
          <Route path="*" element={<Home/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
